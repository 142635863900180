import {useEffect} from "react";
import L from "leaflet";

export const useRouteSections = ({ sections, map, activeSection }) => {

    // Create panes if they don't exist
    if (!map.getPane("activePane")) {
        map.createPane("activePane");
        map.getPane("activePane").style.zIndex = 550; // Higher zIndex for the active section
    }

    if (!map.getPane("inactivePane")) {
        map.createPane("inactivePane");
        map.getPane("inactivePane").style.zIndex = 500; // Lower zIndex for the inactive sections
    }

    useEffect(() => {
        if (!map) return;



        if (sections.length > 0) {
            // Clear previous polylines from the map
            map.eachLayer((layer) => {
                if (layer instanceof L.Polyline) {
                    map.removeLayer(layer);
                }
            });

            let bounds = L.latLngBounds();
            sections.forEach((section, index) => {
                if (section.decodedPolyline && section.decodedPolyline.polyline) {
                    const polylinePoints = section.decodedPolyline.polyline;

                    // Set the color based on whether this section is the active one
                    const color = section?.sectionIndex === activeSection ? "#65a30d" : "#3b45a0";

                    // Create the polyline with the appropriate pane
                    const polyline = L.polyline(polylinePoints, {
                        color,
                        weight: 6,
                        opacity: 0.75,
                        pane: section?.sectionIndex === activeSection ? "activePane" : "inactivePane", // Set the pane here
                    });

                    // Add the polyline to the map
                    polyline.addTo(map);

                    if (section?.sectionIndex === activeSection) {
                        map.fitBounds(polyline.getBounds()); // Zoom in to the active section
                    }

                    bounds.extend(polyline.getBounds());
                }
            });

            // Optional: fit bounds to all sections when no specific section is active
            if (bounds.isValid() && activeSection === null) {
                map.fitBounds(bounds);
            }
        } else {
            console.log("No sections available to draw polylines.");
        }
    }, [map, sections, activeSection]);

    return null;
};