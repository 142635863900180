import { useState, useEffect } from "react";

const useLiveLocation = () => {
  const [position, setPosition] = useState(null);
  const [error, setError] = useState(null);
  const [allowShareLocation, setAllowShareLocation] = useState(false);
  useEffect(() => {
    if (!navigator.geolocation) {
      setError("Geolocation is not supported by your browser");
      return;
    }
    // if (!allowShareLocation) {
    //   setError("You should allow share your location ");
    //   setPosition(null)
    //   return;
    // }

    const watchId = navigator.geolocation.watchPosition(
      (pos) => {
        setPosition({
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude,
        });
      },
      (err) => {
        setError(err.message);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      },
    );

    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }, [allowShareLocation]);

  return { position, error, setAllowShareLocation,allowShareLocation };
};

export default useLiveLocation;
