import L from "leaflet";
import { FaLocationArrow } from "react-icons/fa";
import { useLocation } from "../../hooks";
import useLiveLocation from "../../hooks/useLiveLocation";
import React, {useEffect} from "react";
// import {useLocation} from "../../context";

export const CurrentLocation = React.memo(({ map }) => {
  // const { location } = useLocation();
  const { position, setAllowShareLocation, allowShareLocation } =
      useLiveLocation();
  const handleGoToLocation = () => {
    setAllowShareLocation(prev => !prev);
  };


  useEffect(()=>{
  if(allowShareLocation){
    if(position){
      if (position.latitude && position.longitude) {
        const userLatLng = L.latLng(position.latitude, position.longitude);
        map.setView(userLatLng, 30); // Set the map view to the user's location (zoom level 15)
      } else {
        console.error("Location is not available.");
      }
    }
  }
  },[allowShareLocation, map, position])


  return (
    <div
      style={{ zIndex: 1000 }}
      className="absolute top-28 left-2.5 z-1000 bg-white rounded-lg p-2 inline-block border-2 border-gray-300 hover:accent-neutral-500"
    >
      <FaLocationArrow
        className={allowShareLocation ? "text-primary" : 'text-600'}
        size="20px"
        onClick={handleGoToLocation}
      />
    </div>
  );
});
