import Logo from '../../assets/images/maply-original.png'
export const Loading = () => {
    return (
        <div
            className="flex flex-col justify-center items-center absolute top-0 left-0 right-0 bottom-0 bg-slate-100 z-50"
            style={{
                alignItems: "center",
                zIndex: 1000,
            }}
        >
            <img
                src={`${Logo}`}
                alt="Loading"
                className="w-56 mb-6"
            />
            <div className={'loader'}></div>
        </div>
    );
};

