import ReactDOMServer from "react-dom/server";
import { FaFlag,  } from "react-icons/fa";
import { FaHouse } from "react-icons/fa6";

export const HomeIcon = (L) =>
    new L.DivIcon({
        html: ReactDOMServer.renderToString(
            <div
                style={{
                    width: '24px',
                    height: '24px',
                    backgroundColor: '#3b45a0', // Circle background color
                    borderRadius: '50%', // Makes the div a circle
                    display: 'flex',
                    padding:"3px",
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '2px solid #3b45a0', // Optional: Border around the circle
                }}
            >
                <FaHouse color="white" size="18px" />
            </div>
        ),
        className: "custom-marker-icon",
        iconSize: [40, 40],
    });
export const MarkerIcon = (index, L) =>
  new L.DivIcon({
    html: `
      <div style="
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        background-color: #3b45a0;
        color: white;
        font-weight: bold;
        border-radius: 50%;
      ">
        ${index}
      </div>
    `,
    className: "custom-marker-icon",
    iconSize: [40, 40],
  });
export const FinishIcon = (L) =>
    new L.DivIcon({
        html: ReactDOMServer.renderToString(
            <div
                style={{
                    width: '24px',
                    height: '24px',
                    backgroundColor: '#3b45a0', // Circle background color
                    borderRadius: '50%', // Makes the div a circle
                    display: 'flex',
                    padding:"3px",
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '2px solid #3b45a0', // Optional: Border around the circle
                }}
            >
                <FaFlag color="white" size="18px" />
            </div>
        ),
        className: "custom-marker-icon",
        iconSize: [40, 40],
    });

export const setMarkerIcon = ({ index, length, L }) => {
  if (index === length - 1) {
    return FinishIcon(L);
  }
  switch (index) {
    case 0:
      return HomeIcon(L);
    default:
      return MarkerIcon(index, L);
  }
};

export const LocationIcon = (L,px) =>
    new L.DivIcon({
        html: ReactDOMServer.renderToString(
            <div
                style={{
                    width: 20,
                    height: 20,
                    backgroundColor: '#4c84ee',
                    borderRadius: '50%',
                    border:"2px solid white",
                    // outline: `${px}px solid rgba(76, 132, 238, 0.35)`
                }}
            >
            </div>,
        ),
        className: "custom-marker-icon",
        iconSize: [40, 40],
    });