import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import { FaCheckCircle } from "react-icons/fa";
import { useUpdateRouteById } from "../../hooks/useUpdateRouteById";
import { useEffect, useState } from "react";
import moment from "moment";
import { GiPathDistance } from "react-icons/gi";
import { IoMdTime } from "react-icons/io";
import { FiExternalLink } from "react-icons/fi";
import { FaDirections } from "react-icons/fa";

const OpenMapsButtons = ({ lat, lng }) => {
  const openGoogleMaps = () => {
    const lat = 40.7128;  // Replace with your desired latitude
    const lng = -74.0059;  // Replace with your desired longitude

    const isAndroid = /android/i.test(navigator.userAgent);
    const isIOS = /iphone|ipad|ipod/i.test(navigator.userAgent);

    let googleMapsUrl;
    if (isAndroid) {
      googleMapsUrl = `geo:${lat},${lng}`;  // Use geo: URI scheme for Android
    } else if (isIOS) {
      googleMapsUrl = `http://maps.apple.com/?daddr=${lat},${lng}`;  // Use Apple Maps URL for iOS
    } else {
      // Fallback to web link for other devices
      googleMapsUrl = `https://www.google.com/maps?q=${lat},${lng}`;
    }

    window.open(googleMapsUrl, "_blank");
  };
  return (
    <div className={"text-center flex-1"}>
      <div className={"icon text-primary"}>
        <FaDirections color={"text-primary"} onClick={openGoogleMaps} />
      </div>
      <p className={"text-sm"}>Navigate</p>
    </div>
  );
};

export const SectionNavigator = ({
  onBackClick,
  onNextClick,
  currentSection,
  routeId,
  isNextDisabled,
  isBackDisabled,
}) => {
  const [status, setStatus] = useState(currentSection.status); // Initialize status
  const { updateRouteData, isLoading } = useUpdateRouteById(currentSection);
  const handleUpdate = () => {
    updateRouteData({
      id: routeId,
      waypointId: currentSection?.id,
    });
  };

  const drivingDistance = currentSection?.drivingDistance; // in meters
  const drivingTime = currentSection?.drivingTime; // in seconds

  const distanceInKm = (drivingDistance / 1000).toFixed(2);
  const formattedDistance = `${distanceInKm} km`;

  const duration = moment.duration(drivingTime, "seconds");
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  const formattedTime = `${hours}h ${minutes}`;

  const openZohoLink = () => {
    const link = currentSection?.zohoLink;
    window.open(link, "_blank");
  };

  console.log(isNextDisabled, "isNextDisabled");
  useEffect(() => {
    const newStatus =
      currentSection.status === "uncompleted" || !currentSection.status
        ? "uncompleted"
        : "completed";
    setStatus(newStatus);
  }, [currentSection.status, status]);

  return (
    <div
      style={{
        zIndex: 1000,
      }}
      className="overflow-hidden border border-gray-300 rounded-[24px] z-1000 absolute bottom-0 left-1/2 transform -translate-x-1/2 flex flex-col w-full max-w-sm sm:max-w-md md:max-w-lg lg:max-w-[24rem]"
    >
      <div className="flex justify-between bg-gray-100 p-4">
        {!isBackDisabled ? (
          <AiFillCaretLeft
            className="hover:text-primary transition-colors duration-200 align-baseline flex-shrink-0"
            size={30}
            onClick={onBackClick}
          />
        ) : null}
        <div className="flex flex-col justify-center items-center text-center align-middle flex-grow">
          <p className="text-lg">{currentSection?.name}</p>
          {currentSection?.name !== currentSection?.address ? (
            <p className="text-lg">{currentSection?.address}</p>
          ) : null}
        </div>
        {!isNextDisabled ? (
          <AiFillCaretRight
            className="hover:text-primary transition-colors duration-200 flex-shrink-0"
            size={30}
            onClick={onNextClick}
          />
        ) : null}
      </div>
      <div className="flex justify-around bg-gray-100 pt-0 pr-4 pb-2 pl-4">
        <div className={"flex w-32 justify-center"}>
          <GiPathDistance size={24} fontWeight={"bold"} className={"mr-2"} />
          <p className="text-sm">{`${formattedDistance}`} </p>
        </div>
        <div className={"flex w-32 justify-center"}>
          <IoMdTime size={24} fontWeight={"bold"} className={"mr-2"} />
          <p className="text-sm">{`${formattedTime}m`} </p>
        </div>
      </div>
      <div className="bg-white pt-4 pr-4 pb-6 pl-4">
        <div className="flex  justify-around items-center">
          <OpenMapsButtons lat={currentSection?.lat} lng={currentSection.lng} />
          <div className={"text-center flex-1"}>
            <div
              className={`icon ${isLoading ? "text-400 pulsing" : status === "completed" ? "text-success" : "text-primary"}`}
            >
              <FaCheckCircle onClick={handleUpdate} />
            </div>
            <p className={"text-sm"}>Check In</p>
          </div>

          <div className={"text-center flex-1"}>
            <div className={"icon"}>
              <FiExternalLink onClick={openZohoLink} />
            </div>
            <p className={"text-sm"}>Open In Zoho</p>
          </div>
        </div>
      </div>
    </div>
  );
};
