import { Marker, Popup } from "react-leaflet";
import { LocationIcon, setMarkerIcon } from "../ui/mapIcons";
import L from "leaflet";
import React from "react";

const Markers = React.memo(({ waypoints, currentLocation }) => {
    console.log(currentLocation,'currentLocation')
    return (
        <>
            {currentLocation ? (
                <Marker
                    position={{
                        lat: currentLocation?.latitude,
                        lng: currentLocation?.longitude,
                    }}
                    icon={LocationIcon(L, currentLocation.accuracy)}
                >
                    <Popup>
                        <div className={"flex flex-col justify-center align-middle"}></div>
                    </Popup>
                </Marker>
            ) : null}

            {Array.isArray(waypoints) && waypoints.length > 0 ? (
                waypoints.map((waypoint, index) => (
                    <Marker
                        position={waypoint}
                        icon={setMarkerIcon({ index, length: waypoints.length, L })}
                        key={index}
                    >
                        <Popup>
                            <p
                                style={{ marginBottom: "10px" }}
                                className="text-lg font-semibold text-gray-800 mb-1 leading-none"
                            >
                                {waypoint?.name}
                            </p>
                            <p className="text-sm text-gray-600 m-0 p-0">{waypoint?.address}</p>
                        </Popup>
                    </Marker>
                ))
            ) : null}
        </>
    );
})
export default Markers;
