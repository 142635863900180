import { useLocation, useRouteSections } from "../../hooks";
import Markers from "./markers";
import { useRoute } from "../../context";
import { Loading } from "../shared";
import {useEffect, useMemo, useState} from "react";

import { SectionNavigator } from "./sectionNavigation";
import { CurrentLocation } from "./currentLocation";
import { useWaypoints } from "../../hooks/useWaypoints";
import useLiveLocation from "../../hooks/useLiveLocation";
import useWebSocket from "react-use-websocket";

export const MapContent = () => {
  const { position } = useLiveLocation();

  const { map, loading, route } = useRoute();
  const { waypoints } = useWaypoints(route);
  const [activeSection, setActiveSection] = useState(0);
  const sections = useMemo(() => JSON.parse(route.sections || "[]"), [route.sections]);

  const { sendMessage } = useWebSocket(
    "wss://api.maply.co/api/geolocation",
  );
  useRouteSections({ sections, map, activeSection });
  const handleNextClick = () => {
    if (activeSection < waypoints.length - 2) {
      setActiveSection((prev) => prev + 1);
    }
  };

  const handleBackClick = () => {
    if (activeSection > 0) {
      setActiveSection((prev) => prev - 1);
    }
  };
  useEffect(() => {
    if (position) {
      const message = JSON.stringify({
        latitude: position.latitude,
        longitude: position.longitude,
        timestamp: new Date().toISOString(),
        organizationId: route?.zoho_org_id,
        routeId: route?.id,
      });
      sendMessage(message);
    }
  }, [position, route?.id, route?.zoho_org_id, sendMessage]);

  if (loading) {
    return <Loading />;
  }
  console.log(activeSection,'activeSection')
  console.log(activeSection <= 0,'isBackDisabled')
  return (
    <>
      <CurrentLocation map={map} />
      <Markers
        waypoints={waypoints}
        currentLocation={position}
        currentSection={waypoints[activeSection + 1]}
      />
      <SectionNavigator
        routeId={route?.id}
        onBackClick={handleBackClick}
        onNextClick={handleNextClick}
        isNextDisabled={activeSection >= waypoints.length - 2}
        isBackDisabled={activeSection <= 0}
        currentSection={waypoints[activeSection + 1]}
      />
    </>
  );
};
