import React, { createContext, useContext, useState, useEffect } from "react";
import { useMap } from "react-leaflet";
import { getRouteById } from "../services/httpRequest/apiRequests";

const RouteContext = createContext();

export const useRoute = () => useContext(RouteContext);

export const RouteProvider = ({ children }) => {
    const map = useMap();
    const [route, setRoute] = useState({});
    const [loading, setLoading] = useState(true);
    const queryParameters = new URLSearchParams(window.location.search);
    const routeId = queryParameters.get("route");

    useEffect(() => {
        if (!map) return;
        const fetchRouteData = async () => {
            try {
              setLoading(true)
                const response = await getRouteById(routeId);
                if (response?.data && response.data.length > 0) {
                    const route = response.data[0];
                    setRoute(route)
                }
            } catch (error) {
                console.error("Failed to fetch route data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchRouteData();
    }, [map, routeId]);



    return (
        <RouteContext.Provider value={{  route, map , loading ,setRoute}}>
            {children}
        </RouteContext.Provider>
    );
};