import axios from 'axios';
import { httpHeader } from './httpHeader';
import qs from 'qs';

export default async (props) => {
   const {
      data,
      params,
      url,
      method,
      withoutToken,
      cancelToken,
      headerType,
      responseType,
      serviceName,
      contentType,
      decodeParams,
   } = props;
   const config = {
      url,
      data: data || null,
      params: params || null,
      paramsSerializer: decodeParams ? (params) => qs.stringify(params, { encode: false }) : null,
      method,
      headers: { ['service_name']: serviceName, ...httpHeader({ withoutToken, headerType }) },
      cancelToken: cancelToken || undefined,
      contentType: contentType,
      responseType,
   };
   try {
      return await axios(config);
   } catch (error) {
      return Promise.reject(error);
   }
};
