import { MapContent } from "../components/features";
import { MapContainer, TileLayer } from "react-leaflet";
import {RouteProvider} from "../context/routeProvider";
import React from "react";
export const mapboxURL = `https://api.maptiler.com/maps/f84d30d3-c2b6-4875-95d9-0dc7f25ca6ad/{z}/{x}/{y}@2x.png?key=3hy1ArUUQ3GsUVGBJADy`;



export const Main = () => {




  return (
    <div>
      <MapContainer
        className={"relative transition-all duration-75"}
        zoom={13}
        style={{ height: "100dvh", width: "100%", zIndex: 10 }}
        attributionControl={false} // Disable watermark
      >
        <TileLayer url={mapboxURL} id="mapbox/streets-v11" />
        <RouteProvider>
            <MapContent/>
        </RouteProvider>
      </MapContainer>
    </div>
  );
};
