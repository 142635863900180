import { updateRoute } from "../services/httpRequest/apiRequests";
import { useEffect, useState } from "react";
import {useRoute} from "../context";
const updateWaypoint = (waypoints, updatedRoute) => {
  return waypoints.map(waypoint =>
      waypoint.id === updatedRoute.id ? updatedRoute : waypoint
  );
};


export const useUpdateRouteById = (currentSection) => {
  const {setRoute,route } = useRoute();
  const [isLoading, setIsLoading] = useState(false);
  // const [status, setStatus] = useState(currentSection); // Initialize status

  const updateRouteData = async ({ id, waypointId }) => {
    setIsLoading(true);
    try {
      // Toggle between 'completed' and 'uncompleted'
      const newStatus = currentSection.status === "uncompleted" || !currentSection.status ? "completed" : "uncompleted";
      const res = await updateRoute({
        id,
        waypointId,
        status: newStatus,
      });
      if (res.status === 200) {
        const updatedRoute = res?.data?.updatedRoute;
        setRoute({
          ...updatedRoute,
          sections:route?.sections
        })
      }
    } catch (error) {
      console.error("Error updating route:", error);
    } finally {
      setIsLoading(false);
    }
  };


  return {
    isLoading,
    updateRouteData,
  };
};
