import { httpRequestHeaderTypes, jsonHeader, multiPartHeader } from '../../configs/apiConfig';
import { octetStreamHeader } from '../../configs/apiConfig/apiConfig';

export const httpHeader = (params) => {

	switch (params.headerType) {
		case httpRequestHeaderTypes.MultiPart:
			return {
				...multiPartHeader,
			};
        case httpRequestHeaderTypes.OctetStream:
            return {
                ...octetStreamHeader,
            };
		default:
			return {
				...jsonHeader,
			};
	}
};
