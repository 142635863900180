import httpRequest from "./httpRequest";
import { contentTypes } from "../../configs/apiConfig/apiConfig";
import axios from "axios";

export const getRouteById = async (id) => {
  try {
    const response = await httpRequest({
      url: `https://api.maply.co/api/route/${id}`,
      method: "GET",
      contentType: contentTypes.jsonCharsetUTF8,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const updateRoute = async ({ id, waypointId, status }) => {
  const response = await httpRequest({
    url: `https://api.maply.co/api/route/${id}`,
    method: "PUT",
    contentType: contentTypes.jsonCharsetUTF8,
    data: {
      waypointId,
      status,
    },
  });
  return response;
};
