export const requestTypes = {
   Get: 'GET',
   Post: 'POST',
   Put: 'PUT',
   Delete: 'DELETE',
   Patch: 'PATCH',
};
export const responseTypes = {
   blob: 'blob',
};
export const contentTypes = {
   jsonCharsetUTF8: 'application/json; charset=utf-8',
   octetStream: 'application/octet-stream',
   multipartFormDataL: 'multipart/form-data',
};

const { jsonCharsetUTF8, multipartFormDataL, octetStream } = contentTypes;
export const httpRequestHeaderTypes = {
   Json: 'JSON',
   MultiPart: 'MultiPart',
   OctetStream: 'OctetStream',
};

export const jsonHeader = {
   Accept: 'application/json, text/plain, */*',
   'Content-Type': jsonCharsetUTF8,
};

export const multiPartHeader = {
   Accept: 'application/json',
   'Content-Type': multipartFormDataL,
};
export const octetStreamHeader = {
   Accept: 'application/json, text/plain, */*',
   'Content-Type': octetStream,
};


export const REQUEST = 'REQUEST';



export const EKENG_CREDENTIALS = {
    token: 'e5054fd8-212d-3404-8422-b7725ac87a4b',
    opaque:'ewqytreytwqerwqterwqte'
}
